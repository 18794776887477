$(document).ready(initPage);

function initPage() {
  const swiper = new Swiper('.game-list-swiper', {
    slidesPerView: 3,
    spaceBetween: 20,
    navigation: {
      nextEl: '.section-games .swiper-next',
      prevEl: '.section-games .swiper-prev',
    },
    breakpoints: {
      320: {
        slidesPerView: 1,
      },
      576: {
        slidesPerView: 2,
      },
      992: {
        slidesPerView: 3,
      },
    },
  });

  $('.header-toggler').click(function () {
    $('.header-w').toggleClass('active');
    $('body').toggleClass('menu-isactive');
  });

  setDropdownPosition();

  $(window).on('resize', function () {
    setDropdownPosition();
  });

  $('.gallery-list').magnificPopup({
    delegate: 'a',
    type: 'image',
    gallery: {
      enabled: true,
    },
    image: {
      titleSrc: 'title',
    },
    titleSrc: 'title',
  });

  /*** Calendar ***/

  var events = [
    {
      date: '2022-04-12',
      title: 'Event Title 1',
      location: 'Center 1',
      url: '/index.html',
    },
    {
      date: '2022-04-14',
      title: 'Event Title 2',
      location: 'Center 1',
      url: '/index.html',
    },
    {
      date: '2022-04-17',
      title: 'Event Title 3',
      location: 'Center 1',
      url: '/index.html',
    },
    {
      date: '2022-04-18',
      title: 'Event Title 4',
      location: 'Center 1',
      url: '/index.html',
    },
    {
      date: '2022-04-24',
      title: 'Event Title 5',
      location: 'Center 1',
      url: '/index.html',
    },
    {
      date: '2022-05-08',
      title: 'Event Title 4',
      location: 'Center 1',
      url: '/index.html',
    },
    {
      date: '2022-05-23',
      title: 'Event Title 5',
      location: 'Center 1',
      url: '/index.html',
    },
    {
      date: '2022-06-01',
      title: 'Event Title 4',
      location: 'Center 1',
      url: '/index.html',
    },
    {
      date: '2022-06-14',
      title: 'Event Title 5',
      location: 'Center 1',
      url: '/index.html',
    },
  ];

  if ($('.section-calendar').length) {
    const month1 = moment().format('YYYY-MM');
    const month2 = moment().add(1, 'months').format('YYYY-MM');
    const month3 = moment().add(2, 'months').format('YYYY-MM');
    const month4 = moment().add(3, 'months').format('YYYY-MM');
    const month5 = moment().add(4, 'months').format('YYYY-MM');

    const clndrOptions = {
      template: $('#mini-clndr-template').html(),
      events: events,
      weekOffset: 0,
      showAdjacentMonths: false,
      clickEvents: {
        click: function click(target) {
          if (target.events.length) {
            $('.clndr .day').removeClass('active');
            $(target.element).addClass('active');
            // var daysContainer = $(this)[0].element.find('.days-container');
            // daysContainer.toggleClass('show-events', true); // $('#mini-clndr')
            // $(this)[0]
            //   .element.find('.x-button')
            //   .click(function () {
            //     daysContainer.toggleClass('show-events', false);
            //   });
          }
        },
      },
      adjacentDaysChangeMonth: true,
      forceSixRows: true,
    };
    const clndrOptions1 = {};
    const clndrOptions2 = {};
    const clndrOptions3 = {};
    const clndrOptions4 = {};
    const clndrOptions5 = {};

    function _defineProperty(obj, key, value) {
      if (key in obj) {
        Object.defineProperty(obj, key, {
          value: value,
          enumerable: true,
          configurable: true,
          writable: true,
        });
      } else {
        obj[key] = value;
      }
      return obj;
    }

    Object.assign(
      clndrOptions1,
      clndrOptions,
      _defineProperty({}, 'startWithMonth', month1)
    );
    Object.assign(
      clndrOptions2,
      clndrOptions,
      _defineProperty({}, 'startWithMonth', month2)
    );
    Object.assign(
      clndrOptions3,
      clndrOptions,
      _defineProperty({}, 'startWithMonth', month3)
    );
    Object.assign(
      clndrOptions4,
      clndrOptions,
      _defineProperty({}, 'startWithMonth', month4)
    );
    Object.assign(
      clndrOptions5,
      clndrOptions,
      _defineProperty({}, 'startWithMonth', month5)
    );

    $('#mini-clndr-1').clndr(clndrOptions1);
    $('#mini-clndr-2').clndr(clndrOptions2);
    $('#mini-clndr-3').clndr(clndrOptions3);
    $('#mini-clndr-4').clndr(clndrOptions4);
    $('#mini-clndr-5').clndr(clndrOptions5);
  }
  /* End of Calendar */

  let calendarSwiper = new Swiper('.calendar-swiper', {
    slidesPerView: 1,
    spaceBetween: 20,
    effect: 'fade',
    simulateTouch: false,
    preventClicks: false,
    preventClicksPropagation: false,
    navigation: {
      nextEl: '.calendar-wrapper .swiper-next',
      prevEl: '.calendar-wrapper .swiper-prev',
    },
    breakpoints: {},
    on: {
      init: function () {
        checkCalendarControls(this);
      },
    },
  });

  calendarSwiper.on('slideChange', function () {
    checkCalendarControls(calendarSwiper);
  });

  $('.calendar-wrapper .link-left').click(function () {
    calendarSwiper.slidePrev();
  });
  $('.calendar-wrapper .link-right').click(function () {
    calendarSwiper.slideNext();
  });
}

function setDropdownPosition() {
  $('.header-menu .parent-item ul').each(function () {
    let indent =
      $(this).parent().offset().left - $('.header-menu').offset().left;
    $(this).css('padding-left', indent);
  });
}

function checkCalendarControls(calendar) {
  if (calendar.isBeginning) {
    $('.calendar-wrapper .link-left').addClass('disabled');
  } else {
    $('.calendar-wrapper .link-left').removeClass('disabled');
  }
  if (calendar.isEnd) {
    $('.calendar-wrapper .link-right').addClass('disabled');
  } else {
    $('.calendar-wrapper .link-right').removeClass('disabled');
  }
}
